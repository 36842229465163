import React from "react";
import {
  Asure,
  BMO,
  JPMorgan,
  PayBridge,
  Wagelink,
  Zebec,
} from "../../assets/icons";
import { default as SchoolPayrollServiceImg } from "../../assets/images/school-payroll-service.png";

const InvAndPartners = () => {
  return (
    <>
      <div className="bg-[#F5F5F7]">
        <div className="container pt-24 pb-[177px]">
          <div className="pb-24 max-w-[680px]">
            <h2 className="text-heading-1-mob md:text-heading-1 text-primary">
              PGP Investments and Partners
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10">
            {/* Companies  */}
            <div>
              <div className="inv-border-line"></div>
              <div className="pb-[62px]">
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Portfolio
                </h6>
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col gap-y-1">
                  <a
                    href="https://www.paybridge.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PayBridge width={180} height={40} />
                  </a>
                  <a
                    href="https://www.wagelink.io"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Wagelink width={130} height={28} />
                  </a>
                </div>
                <img src={SchoolPayrollServiceImg} alt="" className="h-20" />
              </div>
            </div>
            {/* Technology Partners  */}
            <div>
              <div className="inv-border-line"></div>
              <div className="pb-[62px]">
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Technology Partners
                </h6>
              </div>
              <div className="flex gap-6 items-center sm:mt-5">
                <a href="https://zebec.io/" target="_blank" rel="noreferrer">
                  <Zebec />
                </a>
                <a
                  href="https://www.asuresoftware.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Asure />
                </a>
              </div>
            </div>
            {/* Banking Partner  */}
            <div>
              <div className="inv-border-line"></div>
              <div className="pb-[62px]">
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Banking Partner
                </h6>
              </div>
              <div className="flex items-center sm:mt-5">
                <JPMorgan height={34} className="mt-[3px]" />
              </div>
            </div>
            {/* Credit Partner  */}
            <div>
              <div className="inv-border-line"></div>
              <div className="pb-[62px]">
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Credit Partner
                </h6>
              </div>
              <div className="flex items-center sm:mt-5">
                <BMO />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvAndPartners;
