import React from "react";
import { FooterLogo } from "../../assets/icons";
import { Link } from "react-router-dom";
const Footer = ({ setIsInvesterLoginOpen }) => {
  return (
    <>
      <div className="bg-primary">
        <div className="container pt-10 pb-11 grid grid-cols-2">
          <div className="self-end">
            <Link to="/" className="">
              <FooterLogo className="h-[42px]" />
            </Link>
          </div>
          <div className="grid justify-end">
            <div className="flex flex-col md:w-[346.5px] h-[116.93px]">
              <Link
                to="/"
                className="text-body-text-exsmall text-secondary hover:text-primary_gray"
              >
                Home
              </Link>
              <button
                onClick={() => setIsInvesterLoginOpen(true)}
                className="text-body-text-exsmall text-secondary hover:text-primary_gray w-fit"
              >
                Investor Login
              </button>
            </div>
            <div>
              <p className="text-primary_gray text-body-text-exsmall ">
                ©{new Date().getFullYear()} Payroll Growth Partners
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
