import React, { useState } from "react";
import { NavLogo, MenuIcon, CrossIcon } from "../../assets/icons";
import { Link } from "react-router-dom";
import InvestorLoginModal from "./InvestorLoginModal";

const Navbar = ({ isInvesterLoginOpen, setIsInvesterLoginOpen }) => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

  return (
    <>
      <nav className="px-4 md:px-6 flex justify-between pt-9 pb-6">
        <Link to="/" className="">
          <NavLogo className="h-5 md:h-8" />
        </Link>
        <div className="md:hidden">
          <button onClick={() => setToggleMobileMenu(true)} className="">
            <MenuIcon />
          </button>
        </div>
        <div className="hidden md:block">
          <div className="flex gap-6 text-nav-text">
            <div
              className={`hover:text-primary_blue ${
                !isInvesterLoginOpen ? "text-primary_blue" : ""
              }`}
            >
              <Link to="/">Home</Link>
            </div>
            <div
              className={`hover:text-primary_blue ${
                isInvesterLoginOpen ? "text-primary_blue" : ""
              }`}
            >
              <button onClick={() => setIsInvesterLoginOpen(true)}>
                Investor Login
              </button>
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile Menu */}
      <div
        className={`fixed z-50 h-screen w-screen bg-primary_blue flex justify-between px-4 py-5 transition-all duration-300 top-0 ${
          toggleMobileMenu ? "left-0 opacity-100" : "-left-full opacity-0"
        }`}
      >
        <div className="flex flex-col gap-2 text-nav-text text-white pt-[240px]">
          <div className={`hover:text-primary`}>
            <Link to="/" onClick={() => setToggleMobileMenu(false)}>
              Home
            </Link>
          </div>
          <div className="hover:text-primary">
            <button
              onClick={() => {
                setIsInvesterLoginOpen(true);
                setToggleMobileMenu(false);
              }}
            >
              Investor Login
            </button>
          </div>
        </div>
        <div className="">
          <button onClick={() => setToggleMobileMenu(false)} className="">
            <CrossIcon />
          </button>
        </div>
      </div>

      {/* Investor Login Modal */}
      <InvestorLoginModal
        isOpen={isInvesterLoginOpen}
        setIsOpen={setIsInvesterLoginOpen}
      />
    </>
  );
};

export default Navbar;
