import React from "react";
import Tech from "./../../assets/images/leadtech.png";
import Tailored from "./../../assets/images/tailored.png";
import Deal from "./../../assets/images/deal.png";

const investments = [
  {
    image: Tech,
    title: "Industry Leading Technology",
    description:
      "Leverage best-in-class technology on pay cards and real-time payroll to accelerate growth and enhance profitability."
  },
  {
    image: Tailored,
    title: "Tailored Flexibility",
    description:
      "Providing liquidity through change-of-control transactions with meaningful upside."
  },
  {
    image: Deal,
    title: "Efficient Deal Execution",
    description: "PGP targets sign-to-close within 30 days."
  }
];

const InvestSection = () => {
  return (
    <>
      <div className="container px-10">
        <div className="max-w-[660px] pb-20">
          <p className="md:text-body-text text-body-text-mob">
            We invest in leading founder-owned payroll companies across diverse
            industries and verticals.
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 place-content-around gap-10">
          {investments.map((investment, index) => {
            return (
              <div
                key={`${investment.title}-${index}`}
                className="bg-secondary_lightgray pb-6"
              >
                <div className="">
                  <img
                    src={investment.image}
                    alt="HeroImage"
                    className="object-cover w-full max-h-[306px] md:max-h-[360px]"
                  />
                </div>
                <div
                  className={`pt-8 pb-16 px-6 ${
                    index === 1 ? "lg:pb-[100px]" : ""
                  } ${
                    index === 2 ? "lg:pb-[100px] xl:pb-16 2xl:pb-[100px]" : ""
                  }`}
                >
                  <p className="text-body-text">{investment.title}</p>
                </div>
                <div className="px-6">
                  <p className="text-body-text-small">
                    {investment.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="border-line mt-28"></div>
    </>
  );
};

export default InvestSection;
