import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./components/Home";
import Navbar from "./components/layouts/Navbar";
import Footer from "./components/layouts/Footer";
import React, { useState } from "react";

function App() {
  const [isInvesterLoginOpen, setIsInvesterLoginOpen] = useState(false);

  return (
    <>
      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <Navbar
          isInvesterLoginOpen={isInvesterLoginOpen}
          setIsInvesterLoginOpen={setIsInvesterLoginOpen}
        />
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Footer setIsInvesterLoginOpen={setIsInvesterLoginOpen} />
      </BrowserRouter>
    </>
  );
}

export default App;
