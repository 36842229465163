import React, { useRef } from "react";
import { ArrowRightIcon } from "../../assets/icons";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const pressNewses = [
  {
    id: 1,
    title: "WageLink: New Payroll App for the Digital Workforce",
    site: "hrtechcube.com",
    link: "https://hrtechcube.com/wagelink-new-payroll-app-for-the-digital-workforce/"
  },
  {
    id: 2,
    title:
      "Revolutionizing Payroll Services: A Journey Into  WageLink’s Innovative Features And Potential Impact On Finance Management",
    site: "b2bdaily.com",
    link: "https://b2bdaily.com/hrtech/revolutionizing-payroll-services-a-journey-into-wagelinks-innovative-features-and-potential-impact-on-finance-management/"
  },
  {
    id: 3,
    title: "PGP acquires School Payroll Services, adds to PayBridge family",
    site: "marketwatch.com",
    link: "https://www.marketwatch.com/press-release/introducing-next-generation-payroll-tech-to-faculty-payroll-growth-partners-acquires-school-payroll-services-adds-to-paybridge-family-28dd2616"
  },
  {
    id: 4,
    title: "Introducing Next Generation Payroll Tech to Faculty",
    site: "morningstar.com",
    link: "https://www.morningstar.com/news/business-wire/20240118638523/introducing-next-generation-payroll-tech-to-faculty-payroll-growth-partners-acquires-school-payroll-services-adds-to-paybridge-family"
  },
  {
    id: 5,
    title: "WageLink: New Payroll App for the Digital Workforce",
    site: "apnews.com",
    link: "https://apnews.com/press-release/business-wire/new-york-ab1533c0617c4f7f8c2bf30e7ee8aaa0"
  },
  {
    id: 6,
    title:
      "Payroll Growth Partners Launches Payroll App With Wage Access, Budgeting Tools",
    site: "pymnts.com",
    link: "https://www.pymnts.com/payroll/2023/payroll-growth-partners-launches-payroll-app-with-wage-access-budgeting-tools/"
  },
  {
    id: 7,
    title: "PGP rolls out upgrade for its client companies",
    site: "electronicpaymentsinternational.com",
    link: "https://www.electronicpaymentsinternational.com/news/pgp-rolls-out-upgrade-client-companies/"
  },
  {
    id: 8,
    title:
      "Payroll Growth Partners Rolls Out Next-Gen Payroll Innovation to Portfolio Companies",
    site: "apnews.com",
    link: "https://apnews.com/press-release/business-wire/new-york-city-business-wire-a0e617fcaf704ce1beefb135cb79b6b0"
  }
  // {
  //   id: 3,
  //   title:
  //     "Payroll Growth Partners (PGP) has announced the launch of new-age payroll products for clients served by companies in its rapidly expanding portfolio.",
  //   site: "techrseries.com",
  //   link: "https://techrseries.com/human-capital-management/payroll-growth-partners-rolls-out-next-gen-payroll-innovation-to-portfolio-companies/?cn-reloaded=1"
  // },
  // {
  //   id: 4,
  //   title: "Transforming the Future of Payroll Landscape for Modern Workforce",
  //   site: "benzinga.com",
  //   link: "https://www.benzinga.com/pressreleases/23/06/b32881241/payroll-growth-partners-rolls-out-next-gen-payroll-innovation-to-portfolio-companies"
  // }
];

const PressSection = () => {
  const swipeRef = useRef(null);

  return (
    <>
      <div className="container">
        <div className="flex items-center justify-between pb-16">
          <h2 className="text-heading-1-mob md:text-heading-1 text-primary">
            In the Press{" "}
          </h2>
          {/* next prev */}
          <div className="flex items-center gap-5">
            <button className="flex-shrink-0 prev-button hover:opacity-70 disabled:opacity-50 disabled:hover:opacity-50">
              <ArrowRightIcon className="w-6 md:w-8 rotate-180" />
            </button>
            <button className="flex-shrink-0 next-button hover:opacity-70 disabled:opacity-50 disabled:hover:opacity-50">
              <ArrowRightIcon className="w-6 md:w-8" />
            </button>
          </div>
        </div>
        <div className="">
          <Swiper
            ref={swipeRef}
            navigation={{
              nextEl: ".next-button",
              prevEl: ".prev-button"
            }}
            slidesPerView={1}
            spaceBetween={24}
            speed={1500}
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 15000
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 24
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 32
              },
              1536: {
                slidesPerView: 4,
                spaceBetween: 32
              }
            }}
          >
            {pressNewses.map((news) => {
              return (
                <SwiperSlide key={news.id}>
                  <div className="py-10 pl-5 pr-7 rounded-xl flex flex-col justify-between gap-y-6 lg:gap-y-10 border border-secondary_midgray">
                    <div className="">
                      <p className="text-nav-text leading-7 text-primary">
                        {news.title}
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-4">
                      <p
                        title={news.site}
                        className="text-primary_blue text-base leading-7 tracking-tighter line-clamp-1"
                      >
                        {news.site}
                      </p>
                      <a
                        href={news.link}
                        target="_blank"
                        rel="noreferrer"
                        className="grid place-content-center border-[3px] border-primary_blue text-primary_blue transition-all hover:text-white hover:cursor-pointer hover:bg-primary_blue w-14 h-14 rounded-full"
                      >
                        <ArrowRightIcon className="w-7 h-7" />
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <p className="text-end text-nav-text mt-12 lg:mt-20">
          For media enquiries, please{" "}
          <span>
            <a
              href="mailto:info@payrollgrowthpartners.com"
              className="text-primary_blue"
            >
              contact us
            </a>
          </span>
        </p>
      </div>
      <div className="border-line-team !mb-0"></div>
    </>
  );
};

export default PressSection;
