import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CrossIcon } from "../../assets/icons";

const InvestorLoginModal = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    closeModal();
  };

  return (
    <Fragment>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-md transform bg-white p-6 md:px-14 md:pt-12 md:pb-16 text-left align-middle shadow-xl transition-all">
                  {/* Close */}
                  <div className="absolute -right-12 md:-right-16 top-0">
                    <button onClick={() => closeModal()}>
                      <CrossIcon />
                    </button>
                  </div>
                  {/* Dialog */}
                  <div className="">
                    <p className="md:text-body-text text-body-text-mob pb-12 md:pb-16">
                      Investor Login
                    </p>
                    <form onSubmit={handleSubmit} className="">
                      <div className="flex flex-col px-1 pt-1 border border-primary_blue mb-7">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="name@provider.com"
                          className="focus:border-none focus:outline-none"
                          autoComplete="true"
                          required
                        />
                      </div>
                      <div className="flex flex-col px-1 pt-1 border border-primary_blue mb-16">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          placeholder="XXXXXXXXXXXXX"
                          className="focus:border-none focus:outline-none"
                          autoComplete="false"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-full body-text-exsmall px-6 py-4 bg-primary_blue text-white"
                      >
                        Login
                      </button>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default InvestorLoginModal;
