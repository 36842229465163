import React from "react";

const FindMoreSection = () => {
  return (
    <>
      <div className="find-more h-[390px] md:h-[430px] pt-[110px] md:pt-[150px]">
        <div className="container">
          <div className="max-w-[746px]">
            <h2 className="text-heading-1-mob md:text-heading-1 text-primary">
              Find out more <br />
              <span className="text-primary_blue transition hover:text-primary">
                <a href="mailto:info@payrollgrowthpartners.com">contact us</a>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default FindMoreSection;
