import React from "react";
import Simon from "./../../assets/images/simon.png";
import Neal from "./../../assets/images/neal.png";
import Parth from "./../../assets/images/parth.png";
import Lisa from "./../../assets/images/lisa.png";
import Joe from "./../../assets/images/joe.png";
import Tom from "./../../assets/images/tom.png";
import Aniruddha from "./../../assets/images/aniruddha.png";
import Elena from "./../../assets/images/elena.png";
import George from "./../../assets/images/george.png";

const TeamSection = () => {
  return (
    <>
      <div className="container">
        <div className="pb-16">
          <h2 className="text-heading-1-mob md:text-heading-1 text-primary">
            {" "}
            Meet our team{" "}
          </h2>
        </div>
        <div className="max-w-[635.21px] pb-20">
          <p className=" text-body-text-small text-primary">
            Our team has led over $2BN in investment and acquisition-based
            growth strategies.
          </p>
        </div>
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-x-40 gap-y-14">
            {/* 1st image */}
            <div>
              <div className="pb-6">
                <img
                  src={Simon}
                  alt="Simon Babakhani"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Simon Babakhani
                </h6>
                <p className="text-body-text-exsmall">
                  Founder/Managing Partner
                </p>
              </div>
            </div>
            {/* 3rd image */}
            <div>
              <div className="pb-6">
                <img
                  src={Neal}
                  alt="Neal Padhye"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Neal Padhye
                </h6>
                <p className="text-body-text-exsmall">Partner</p>
              </div>
            </div>
            {/* 8th Image */}
            <div>
              <div className="pb-6">
                <img
                  src={Aniruddha}
                  alt="Aniruddha Dasgupta"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Aniruddha Dasgupta
                </h6>
                <p className="text-body-text-exsmall">Operating Partner</p>
              </div>
            </div>
            {/* 4th image */}
            <div>
              <div className="pb-6">
                <img
                  src={Elena}
                  alt="Elana Solovyov"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Elena Solovyov
                </h6>
                <p className="text-body-text-exsmall">
                  Chief Marketing Officer
                </p>
              </div>
            </div>
            {/* 4th image */}
            <div>
              <div className="pb-6">
                <img
                  src={Parth}
                  alt="Parth Shah"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Parth Shah
                </h6>
                <p className="text-body-text-exsmall">Associate</p>
              </div>
            </div>
            {/* 5th image */}
            <div>
              <div className="pb-6">
                <img
                  src={Lisa}
                  alt="Lisa Robbins"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Lisa Robbins
                </h6>
                <p className="text-body-text-exsmall">Advisor</p>
              </div>
            </div>
            {/* 6th image */}
            <div>
              <div className="pb-6">
                <img
                  src={Joe}
                  alt="Joe Chevalier"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Joe Chevalier
                </h6>
                <p className="text-body-text-exsmall">Advisor</p>
              </div>
            </div>
            {/* 7th Image */}
            <div>
              <div className="pb-6">
                <img
                  src={Tom}
                  alt="Tom Lutz"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  Tom Lutz
                </h6>
                <p className="text-body-text-exsmall">Advisor</p>
              </div>
            </div>
            {/* 2nd image */}
            <div>
              <div className="pb-6">
                <img
                  src={George}
                  alt="George Stoeckert"
                  className="object-cover person-img"
                />
              </div>
              <div>
                <h6 className="text-body-text-exsmall text-primary_blue">
                  George Stoeckert
                </h6>
                <p className="text-body-text-exsmall">Advisor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-line-team"></div>
    </>
  );
};

export default TeamSection;
