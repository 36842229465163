import React from "react";
import HeroImage from "./../../assets/images/heroImage.png";

const HeroSection = () => {
  return (
    <>
      <div className="bg-secondary_lightgray hero-section relative">
        <div className="px-4 md:px-6 grid md:grid-cols-2 h-full">
          <div className="grid pb-14 md:pb-24">
            <p className="text-heading-1-mob md:text-heading-1 mt-[120px] md:mt-auto">
              Investing in the
              <br />
              <span className="text-primary_blue">payroll companies</span>
              <br /> of the future.
            </p>
          </div>
          {/* <div className=""></div> */}
        </div>
        <div className="flex md:absolute md:top-0 md:right-0 md:bottom-0 md:left-1/2">
          <img
            src={HeroImage}
            alt="HeroImage"
            className="object-cover w-full h-[394px] md:h-auto"
          />
        </div>
      </div>
      <div className="border-line"></div>
    </>
  );
};

export default HeroSection;
