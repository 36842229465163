import React from "react";
import HeroSection from "./HeroSection";
import InvestSection from "./InvestSection";
import TeamSection from "./TeamSection";
import InvAndPartners from "./InvAndPartners";
import FindMoreSection from "./FindMoreSection";
import PressSection from "./PressSection";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <InvestSection />
      <TeamSection />
      <PressSection />
      <InvAndPartners />
      <FindMoreSection />
    </>
  );
};

export default HomePage;
